import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OEHttpClient } from '../core';

@Injectable()
export class UserService {
  private _adminEndpoint = 'v1/admin';
  private _userEndPoint = 'v1/admin/Users';
  private _userV2EndPoint = 'v2/User';
  private _userSummaryEndPoint = `${this._userEndPoint}/summary`;
  private _connectUsersSearchEndPoint = 'v1/admin/Users/connect';
  private _userProfileEndPoint = 'v1/admin/users/profile';
  private _userTeamsEndPoint = 'v1/admin/user/teams';
  private _serviceTeamsEndPoint = 'v2/serviceTeams/';

  constructor(private _httpClient: OEHttpClient) { }

  /** Get user summary count */
  getUserSummary() {
    return this._httpClient.getData(this._userSummaryEndPoint);
  }

  getUser() {
    return this._httpClient.getData(`${this._adminEndpoint}/authorization/user`);
  }

  getOrionUsers(id: string, firmId?: any) {
    let addParams = '';
    if (id) {
      id = encodeURIComponent(id);
    }
    if (firmId) {
      addParams = `?connectFirmId=${firmId}`;
    }
    return this._httpClient.getData(`${this._connectUsersSearchEndPoint}/${id}${addParams}`);
  }

  /** Get user by Id */
  getUsers() {
    return this._httpClient.getData(this._userEndPoint);
  }

  /** Get user by Id */
  getUserById(userId: number) {
    return this._httpClient.getData(`${this._userEndPoint}/${userId}`);
  }

  /** To create user */
  createUser(user: any) {
    return this._httpClient.postData(this._userEndPoint, user);
  }

  /** To delete user */
  deleteUser(userId: number) {
    return this._httpClient.deleteData(`${this._userEndPoint}/${userId}`);
  }

  /** To search user */
  searchUser(searchString: string) {
    return this._httpClient.getData(`${this._userEndPoint}?search=${searchString}`);
  }

  /** Update a user */
  updateUser(userId: number, user: any): Observable<any> {
    return this._httpClient.updateData(`${this._userEndPoint}/${userId}`, user);
  }

  /** Patch user data */
  patchUser(userId: number, patchData: {op: string, path: string, value: any}[]): Observable<any> {
    return this._httpClient.patchData(`${this._userV2EndPoint}/${userId}`, patchData);
  }

  /** Update User profile */
  updateUserProfileImage(img): Observable<any> {
    const formData: any = new FormData();
    formData.append('logo', img);
    return this._httpClient.uploadFile(this._userProfileEndPoint, formData);
  }

  /** Get logged in user specific teams, to dispaly on add new portfolio page */
  getUserSpecificTeams(): Observable<any> {
    return this._httpClient.getData(this._userTeamsEndPoint);
  }

  getOrionServiceTeam(): Observable<any> {
    return this._httpClient.getData(`${this._serviceTeamsEndPoint}GetServiceTeam`);
  }

  getOrionServiceTeams(serviceType: string): Observable<any> {
    return this._httpClient.getData(`${this._serviceTeamsEndPoint}GetServiceTeams?serviceType=${serviceType}`);
  }

  getOrionAdvisorNumber(): Observable<any> {
    return this._httpClient.getData(`${this._serviceTeamsEndPoint}GetAdvisorNumber`);
  }
}
