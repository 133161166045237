import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  EditorEntityType,
  EntityEditorService,
  IEntityEditorComponent,
  IEntityEditorConfig,
} from '../../../shared/entity-editor';
import * as Consts from '../../../libs/app.constants';
import { SessionHelper } from '../../../core';
import { Subscription } from 'rxjs';
import { ITabNav } from '../../../viewModels/tabnav';
import { IAccount, IPortfolioDetails, IToken } from '../../../models/portfolio';
import { ConfigService } from '../../../config/config.service';
import { LoginAsService } from '../../../services/loginas.service';
import { PortfolioService } from '../../../services/portfolio.service';
import { PreferenceService } from '../../../services/preference.service';
import { WINDOW } from '../../../providers/window.provider';
import { Utils as Util } from '../../../core/functions';
import { BaseComponent } from '../../../core/base.component';
import { IEntityChangedEvent } from '../../../shared/portfoliotree/portfolio-tree-item';
import { EntityType } from '../../../libs/preference.enums';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';
import { ActionMenuUtilities } from '../../../shared/actions/actions-menu';

@Component({
  selector: 'eclipse-portfolio-editor',
  templateUrl: './portfolio-editor.component.html'
})
export class PortfolioEditorComponent  extends BaseComponent implements IEntityEditorComponent {
  @ViewChild('entityhost', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  @ViewChild('menu') menuElement: ElementRef;

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _visible: boolean = false;
  @Input()
  public get visible(): boolean {
    return this._visible;
  }

  public set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);
  }

  public get model(): ITabNav {
    return this.componentInstance?.tabsModel;
  }

  public get isEntityLoaded(): boolean {
    return this.componentInstance?.portfolio?.id || this.model?.action === 'A';
  }

  accountComponentRef: ComponentRef<any>;
  componentInstance: any;
  entityEditorConfig: IEntityEditorConfig;
  componentCloseSubscription: Subscription;

  public accountAccessPermission: boolean = false;
  public accountEditPermission: boolean = false;
  public portfolioEditPermission: boolean = false;
  public reverseSyncPortfolioPreference: boolean = false;
  public closeOnSkippedNavigationFlag = false;
  massLiquidateFF: boolean;

  constructor(private readonly sessionHelper: SessionHelper, private _loginAsService: LoginAsService, private _portfolioService: PortfolioService,
              private readonly _preferenceService: PreferenceService, private readonly _entityEditorService: EntityEditorService, @Inject(WINDOW) private window: Window,
              private readonly _splitIoService: SplitIoService) {
    super();

    const accountPriv = Util.getPermission(Consts.PRIV_ACCOUNTS);
    this.accountAccessPermission = !!accountPriv?.canRead;
    this.accountEditPermission = !!accountPriv?.canUpdate;
    this.portfolioEditPermission = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;

    _preferenceService.getPreferenceByName('reverseSyncPortfolios')
      .subscribe(result => {
        this.reverseSyncPortfolioPreference = Util.convertIntoBooleanValue(result);
      });
    this._splitIoService.flagsEnabled([
      'Eclipse_PopupEditorNavigation_254821',
      'mass_liquidation_f211351'
    ])
      .subscribe((flags: { [key: string]: boolean }) => {
        this.closeOnSkippedNavigationFlag = !!flags['Eclipse_PopupEditorNavigation_254821'];
        this.massLiquidateFF = flags['mass_liquidation_f211351'];
      });
  }

  ngAfterViewChecked() {
    ActionMenuUtilities.removeDuplicateMenuDividers(this.menuElement);
  }

  async ngAfterViewInit() {
    // Create the existing account detail page for now.  In the future we'll replace the entire HTML template for this component with the new editor.
    this.accountComponentRef = await this.createComponent();
    if (!this.accountComponentRef) {
      return;
    }
    this.componentInstance = this.accountComponentRef.instance;
    this.componentInstance.hostedInEditor = true;
    this.componentInstance.portfolioId = this.entityEditorConfig?.data?.id;
    this.componentCloseSubscription = this.componentInstance.onClose?.subscribe(val => {
      this.visible = false;
    });
  }

  ngOnDestroy() {
    this.componentCloseSubscription?.unsubscribe();
    this.viewContainerRef.clear();
    this.componentInstance?.destroy?.();
    this.accountComponentRef?.destroy?.();
    this.accountComponentRef = null;
    this.componentInstance = null;
  }

  public closeEditor(): void {
    if(this.componentInstance?.changedEntityMessage) {
      this._entityEditorService.entityChangedMessage(this.componentInstance.changedEntityMessage)
    }
    this._entityEditorService.close();
  }

  private async createComponent() {
    this.viewContainerRef.clear();
    if (!this.entityEditorConfig.skipAddingQueryParameter) {
      this._entityEditorService.addQueryParameter();
    }
    const privilege = this.sessionHelper.getPermission(Consts.PRIV_PORTFOLIOS);
    if (privilege?.canUpdate && (this.entityEditorConfig.data.editMode === undefined || !!this.entityEditorConfig.data?.editMode)) {
      const { PortfolioDetailComponent } = await import('./portfoliodetail.component');
      return this.viewContainerRef.createComponent(PortfolioDetailComponent);
    } else if (privilege?.canRead) {
      const { PortfolioViewComponent } = await import('../view/portfolioview.component');
      return this.viewContainerRef.createComponent(PortfolioViewComponent);
    }
    console.error('No Portfolio component was created.  Check user portfolio permissions:', privilege);
    return null;
  }

  /** Action Menu */
  onCancel() {
    if(this.componentInstance?.changedEntityMessage) {
      this._entityEditorService.entityChangedMessage(this.componentInstance.changedEntityMessage)
    }
    this.visible = false;
  }

  onTransferSleeve(portfolioId: number) : void {
    this._portfolioService.getOCSleeveTransferUri(portfolioId)
      .subscribe((connectTransferUri: string) => {
        if (connectTransferUri) {
          this.window.open(connectTransferUri + '&isFullPage=true');
        }
      });
  }

  onSleeveEdit(portfolioId) {
    this._portfolioService.getPortfolioAccounts(portfolioId)
      .subscribe((accounts: IAccount[]) => {
        const accountOrionFirmId = accounts[0].ocFirmId;
        this._loginAsService.getFirmToken(accountOrionFirmId)
          .subscribe((token: IToken) => {
            if (portfolioId > 0) {
              this._portfolioService.getPortfolioById(portfolioId)
                .subscribe((portfolio: IPortfolioDetails) => {
                  let registrationId = portfolio.general.registrationId;
                  const connectSleeveEditUrl = ConfigService.settings.orionAdvisorEndPoint + 'orionconnectapp/integration.html?p=/portfolio/edit/registrations/'+ registrationId + '?tabCode=sleeveSetup&m=crm&t=' +  token.orion_access_token;
                  this.window.open(connectSleeveEditUrl);
                });
            }
          });
      });
  }

  allowRebalance() {
    return this.model?.isDisabled !== 1 && this.model?.rebalancerToolPermission
  }

  allowCashNeeds() {
    return this.model?.type !== 'A' && this.model?.cashNeedsToolPermission
  }

  allowSleeveRebalance() {
    return this.model?.sleeves?.length && this.model?.isDisabled !== 1 && this.model?.rebalancerToolPermission;
  }

  allowTradeToTarget() {
    return this.model?.action !== 'E' && this.model?.action !== 'A' && this.model?.tradeToTargetToolPermission;
  }

  addNewPortfolio() {
    this._entityEditorService.show({entityType: EditorEntityType.Portfolio, data: {editMode: true}});
  }

  onTreeEntityChanged(entity: IEntityChangedEvent): void {
    switch (entity.entityType) {
      case EntityType.Portfolio:
        this._entityEditorService.show({
          entityType: EditorEntityType.Portfolio,
          data: {id: entity.id, editMode: !!this.portfolioEditPermission},
        });
        break;
      case EntityType.Account:
        this._entityEditorService.show({
          entityType: EditorEntityType.Account,
          data: {id: entity.id, editMode: false}, // always go to View page by default
        });
        break;
      default:
        console.error('Unsupported entity type', entity);
    }
  }
}
