import {Component, Input, Output, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../core/base.component';
import {IAccountSimpleSearch} from '../../models/account';
import {AccountService} from '../../services/account.service';
import {ITradeGridItem} from '../../models/spendcash';

@Component({
  selector: 'eclipse-account-auto-complete',
  templateUrl: './account.autocomplete.component.html'
})
export class AccountsAutoCompleteComponent extends BaseComponent {
  @Input() isSpendCash: boolean;
  @Input() excludeDoNotTrade = false;
  @Input() List: Array<ITradeGridItem> = [];
  searchText: string;
  suggestions: IAccountSimpleSearch[] = [];
  selectedItem: string;

  @Output() parentCallback = new EventEmitter();
  @Output() onValueChange = new EventEmitter();
  constructor(private readonly _accountService: AccountService) {
    super();
  }

  /** To get accounts for search autoComplete  */
  loadSuggestions(event) {
    this.searchText = event.query.trim();
    this._accountService.searchAccountsByIdValue(event.query.toLowerCase(), this.isSpendCash, this.excludeDoNotTrade)
      .subscribe((model: IAccountSimpleSearch[]) => {
        const addedIds = this.List.map(l => l.id);
        this.suggestions = model.filter(element => !addedIds.includes(element.id) && !element.isDeleted);
        this.formatAccountSuggestions(this.suggestions, event);
      });
  }

  /** Fires on item select */
  onAccountSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({ value: this.selectedItem });
  }

  onAccountValueChange(value) {
    this.onValueChange.emit({ value: value });
  }
}
