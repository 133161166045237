<p-dropdown [options]="hierarchy" [(ngModel)]="selectedHierarchyEntity" styleClass="portfolio-hierarchy" placeholder="{{!!hierarchy ? 'Select an entity' : 'Loading...'}}"
            optionValue="uniqueId"
            [filter]="true" filterBy="name,accountNumber,displayId" (onChange)="onChange($event)" name="selectedEntity" [disabled]="disabled">
  <ng-template let-item pTemplate="selectedItem">
    <div [ngSwitch]="item.entityType">
      <div *ngSwitchCase="entityTypes.Portfolio" class="hierarchy-item">
        <div>
          <span class="portfolio-text-color">{{ item.id }}</span>
          <span> {{ item.name }}</span>
        </div>
      </div>
      <div *ngSwitchCase="entityTypes.Account" class="hierarchy-item">
        <div>
          <span class="account-text-color">{{ item.displayId }}</span>
          <span> {{ item.name }}</span>
          <span> • </span>
          <span>{{ item.accountType }}</span>
          <span> • </span>
          <span>{{ item.accountNumber }}</span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div [ngSwitch]="item.entityType">
      <div *ngSwitchCase="entityTypes.Portfolio" class="hierarchy-item hierarchy-level-1">
        <div>
          <i class="far fa-cubes m-r-5 portfoilo-tree-icon" aria-hidden="true"></i>
          <span class="portfolio-text-color">{{ item.displayId }}</span>
          <span> {{ item.name }}</span>
        </div>
        <span class="m-l-10">{{ item.totalValue | currency }}</span>
      </div>
      <div *ngSwitchCase="entityTypes.Account" class="hierarchy-item hierarchy-level-2">
        <div>
          <i class="far fa-briefcase m-r-5 account-tree-icon" aria-hidden="true"></i>
          <span class="account-text-color">{{ item.displayId }}</span>
          <span> {{ item.name }}</span>
          <span> • </span>
          <span>{{ item.accountType }}</span>
          <span> • </span>
          <span>{{ item.accountNumber }}</span>
        </div>
        <span class="m-l-10">{{ item.totalValue | currency }}</span>
      </div>
    </div>
  </ng-template>
</p-dropdown>
