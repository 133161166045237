export const Actions = {
    MODIFY: 'modify',
    ADD: 'add',
    REMOVE: 'remove'
};

export const ModelType = {
    CATEGORY: 1,
    CLASS: 2,
    SUBCLASS: 3,
    SECURITY_SET: 4
};

export const ModelTypeName = {
    CATEGORY: 'CATEGORY',
    CLASS: 'CLASS',
    SUBCLASS: 'SUBCLASS',
    SECURITY_SET: 'SECURITYSET',
    SECURITY: 'SECURITY'
};
