import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { EditorEntityType, IEntityEditorComponent, IEntityEditorConfig, IEntityMessage } from './';
import { AccountEditorComponent } from '../../components/account/detail/account-editor.component';
import { Subscription, Subject } from 'rxjs';
import { PortfolioEditorComponent } from '../../components/portfolio/detail/portfolio-editor.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EntityEditorService {
  private entityChangedMessageSource = new Subject<IEntityMessage>();
  entityChanged = this.entityChangedMessageSource.asObservable();
  public viewContainerRef: ViewContainerRef; // ViewContainerRef is used to dynamically create components.
  private editorComponentRef: ComponentRef<IEntityEditorComponent>; // Reference to the ComponentRef (editor component)
  private entityEditorInstance: IEntityEditorComponent; // Reference to the actual editor component instance
  private visibleChangeSubscription: Subscription; // Subscription to the editor visibility change event.

  /**
   * Is an entity editor currently visible on screen.
   */
  public get isVisible(): boolean {
    return !!this.entityEditorInstance?.visible;
  }

  constructor(private readonly _router: Router, private readonly route: ActivatedRoute) {
  }

  entityChangedMessage(entityMessage: IEntityMessage) {
    this.entityChangedMessageSource.next(entityMessage)
  }

  /**
   * Creates an entity editor component using the configuration options.
   * @param config
   */
  public show(config: IEntityEditorConfig): IEntityEditorComponent {
    if (!config) {
      throw new Error('Editor configuration is required');
    }
    // If there is already an editor, destroy it before creating a new one so we don't have a memory leak
    if (!!this.editorComponentRef) {
      this.destroyComponent();
    }

    // Create the new component using the config options
    this.editorComponentRef = this.createComponentFromConfig(config);

    // Bail if the component failed to create for some reason.
    if (!this.editorComponentRef) {
      return;
    }

    // Set the options on the editor component
    this.entityEditorInstance = this.editorComponentRef.instance;
    this.setupEditor(config);
    return this.entityEditorInstance;
  }

  /**
   * Closes the open editor (if any)
   */
  public close(): void {
    if (this.entityEditorInstance) {
      this.entityEditorInstance.visible = false;
      this.removeQueryParameter();
    }
  }

  /**
   * Sets the initial options on the editor and makes it visible.
   * @param config
   * @private
   */
  private setupEditor(config: IEntityEditorConfig) {
    this.entityEditorInstance.entityEditorConfig = config;
    this.entityEditorInstance.visible = true;

    // When closing the editor, destroy all the components and clear the component host container.
    this.visibleChangeSubscription = this.entityEditorInstance.visibleChange.subscribe(visible => {
      if (!visible) {
        this.destroyComponent();
      }
    });
  }

  /**
   * Destroys the hosted editor component view.
   * @private
   */
  public destroyComponent(): void {
    this.visibleChangeSubscription?.unsubscribe();
    this.viewContainerRef?.clear();
    this.editorComponentRef?.destroy();
    this.editorComponentRef = null;
    this.entityEditorInstance = null;
  }

  /**
   * Creates a new editor component based on the `config` options.
   * @param config
   */
  public createComponentFromConfig(config: IEntityEditorConfig): ComponentRef<IEntityEditorComponent> {
    // Clear any existing components in the host container
    this.viewContainerRef?.clear();
    // Use the ViewContainerRef to create a new component.  This will host the new component inside the ViewContainerRef.
    switch (config.entityType) {
      case EditorEntityType.Account:
        return this.viewContainerRef.createComponent(AccountEditorComponent);
      case EditorEntityType.Portfolio:
        return this.viewContainerRef.createComponent(PortfolioEditorComponent);
      default:
        throw new Error(`Unknown entity type for editor: ${config.entityType}`);
    }
  }

  /**
   * Removes the entity editor query parameters from the url.  Used when the entity editor has been closed.
   */
  public removeQueryParameter() {
    const param = {};
    param['editEntity'] = null;
    const queryParams: NavigationExtras = {
      queryParams: param,
      queryParamsHandling: 'merge',
      preserveFragment: true,
    };
    this._router.navigate([], queryParams);
  }

  /**
   * Adds the entity editor query parameters to the url.  Used when the editor is opened because of an action
   * like a button click.
   */
  public addQueryParameter() {
    const newQueryParam = {};
    const currentParams = { ...this.route.snapshot.queryParams}; // backup of the current parameters so they aren't overwritten
    const config = this.entityEditorInstance.entityEditorConfig;
    const value = config?.data?.id;

    if (!value) {
      console.error('Entity editor requires an Entity ID', config);
      return;
    } else if (!EntityEditorService.isSupportedEntityType(config.entityType)) {
      console.error('Entity editor requires a valid Entity Type', config);
      return;
    }

    newQueryParam['editEntity'] = `${config.entityType}-${value}`;
    const queryParams: NavigationExtras = {
      queryParams: newQueryParam,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      state: { previousParams: {...currentParams, config: config } }
    };
    this._router.navigate([], queryParams);
  }

  /**
   * Converts a string to an Entity Editor Type enum
   * @param typeString
   */
  static entityTypeFromString(typeString: string): EditorEntityType {
    switch (typeString?.toLowerCase()) {
      case 'account':
        return EditorEntityType.Account;
      case 'portfolio':
        return EditorEntityType.Portfolio;
      default:
        return null;
    }
  }

  /**
   * Checks a string against the supported types of entities that can be loaded into an editor.
   * @param value
   */
  static isSupportedEntityType(value: string): value is EditorEntityType {
    return Object.values(EditorEntityType).includes(value as EditorEntityType);
  }
}
