import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import {ILoginAs} from '../models/loginas';
import {Observable} from 'rxjs';
import {ILogin} from '../login/login';

@Injectable({
  providedIn: 'root'
})
export class LoginAsService {
  private _loginAsEndPoint = 'v1/admin/token/loginas';
  private _revertLoginAsEndPoint = 'v1/admin/token/loginas/revert';
  private _superAdmin = 'v1/admin/firms';
  private _switchFirm = 'v1/admin/token/firm';
  private _getFirmToken = 'v1/admin/token/firm/';
  private _getMergeFirmsEndPoint = 'v1/admin/firms/mergeFirms';
  private _orionConncetFirmsEndPoint = 'v1/admin/firms/connectFirms';

  constructor(private _httpClient: OEHttpClient) { }

  /**To Post LoginAs information */
  loginAs(data: ILoginAs): Observable<ILogin> {
    return this._httpClient.postData(this._loginAsEndPoint, data);
  }

  /**To get the revert login as Response */
  revertLoginAs(): Observable<ILogin> {
    return this._httpClient.getData(this._revertLoginAsEndPoint);
  }
  /**To get firms list for super admin user */
  getSuperAdminFirmList() {
    return this._httpClient.getData(this._superAdmin);
  }
  switchFirm(firmId: number): Observable<ILogin> {
    return this._httpClient.postData(this._switchFirm, { firmId: firmId });
  }
  getFirmToken(firmId: number) {
    return this._httpClient.getData(this._getFirmToken + firmId);
  }
  /**To get firms list for Firm admin user */
  getAdminFirmList() {
    return this._httpClient.getData(this._getMergeFirmsEndPoint);
  }

  /**To get orion conncet firms list */
  getOrionConnectFirmList() {
    return this._httpClient.getData(this._orionConncetFirmsEndPoint);
  }

  /**To save admin firm list chnages */
  saveAdminFirmListChanges(data: any) {
    return this._httpClient.postData(this._getMergeFirmsEndPoint, data);
  }

  searchFirm(searchString: string) {
    searchString = searchString.trim();
    return this._httpClient.getData(`${this._superAdmin}?search=${searchString}`);
  }
}
